import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowDropDown,
  MdCampaign,
  MdCancel,
  MdChildCare,
  MdClose,
  MdFacebook,
  MdFavorite,
  MdFavoriteBorder,
  MdHome,
  MdMenu,
  MdMood,
  MdOutlineAirplanemodeActive,
  MdOutlineCampaign,
  MdOutlineComputer,
  MdOutlineFitnessCenter,
  MdOutlineFoodBank,
  MdOutlineGamepad,
  MdOutlinePets,
  MdOutlineRestaurant,
  MdOutlineShoppingBag,
  MdOutlineSpa,
  MdPerson,
  MdPersonOutline,
  MdSearch,
} from "react-icons/md";

import "../../styles/Card.css";
import { useNavigate } from "react-router";
import {
  FaInstagram,
  FaLinkedin,
  FaMastodon,
  FaPinterest,
  FaPlay,
  FaSnapchat,
  FaTiktok,
  FaTwitch,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { isEmpty } from "../../functions/isEmpty";
import { TbWorldWww } from "react-icons/tb";
import MediaPlayer from "../mediaPlayer/MediaPlayer";

export default function Card({
  isMobile,
  data,
  setActiveProductID,
  productID,
  givenProductIDForMobile,
}) {
  let navigate = useNavigate();

  const handleClick = () => {
    if (isMobile) {
      setActiveProductID(givenProductIDForMobile);
    }
    navigate("/creator/" + data.id);
  };
  return (
    <div className="card" onClick={() => handleClick()}>
      <div className="card_top">
        <div className="card_top_imagebox">
          <img
            key={data.id}
            src={!isEmpty(data) ? JSON.parse(data.pictures)[0].url : ""}
            className="card_top_imagebox_img"
            type="creator"
            id="mediaMobile"
          />
        </div>
      </div>
      <div className="card_bottom">
        <div className="card_bottom_top">{data?.companyname}</div>
        <div className="card_bottom_middle">
          {data?.followerInstagram} Follower
        </div>
        <div className="card_bottom_bottom">
          {data?.priceInstagramStory}€/Story
        </div>
      </div>
    </div>
  );
}

const PersonIcon = styled(MdPersonOutline)`
  font-size: 26px;
  color: #8f8f8f;
`;

const BackIcon = styled(MdArrowBackIos)`
  font-size: 27px;
  color: #f58700;
  margin-right: -15px;
`;

const CampaignIcon = styled(MdOutlineCampaign)`
  font-size: 26px;
  color: #ffffff;
`;

const CampaignIconGrey = styled(MdOutlineCampaign)`
  font-size: 26px;
  color: #696969;
`;

const CancelIcon = styled(MdClose)`
  font-size: 26px;
  color: #696969;
  margin-top: 3px;
`;

const FavoriteIcon = styled(MdFavoriteBorder)`
  font-size: 26px;
  color: #ffffff;
`;

const FavoriteIconActive = styled(MdFavorite)`
  font-size: 26px;
  color: #f58700;
`;

const InstgramIcon = styled(FaInstagram)`
  font-size: 20px;
  color: #8c8c8c;
`;

const TikTokIcon = styled(FaTiktok)`
  font-size: 17px;
  color: #8c8c8c;
  margin-left: 3px;
  margin-bottom: 2px;
  margin-top: 2px;
`;

const YouTubeIcon = styled(FaYoutube)`
  font-size: 20px;
  color: #8c8c8c;
  margin-left: 3px;
`;

const UGCIcon = styled(FaPlay)`
  font-size: 13px;
  color: #8c8c8c;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const SnapchatIcon = styled(FaSnapchat)`
  font-size: 20px;
  color: #8c8c8c;
  margin-top: 2px;
  margin-bottom: 3px;
`;

const FacebookIcon = styled(MdFacebook)`
  font-size: 20px;
  color: #8c8c8c;
`;
