import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBack,
  MdArrowForward,
  MdAttachFile,
  MdCancel,
  MdMenu,
  MdMoreHoriz,
  MdOutlineImage,
} from "react-icons/md";

import "../../styles/TextInput.css";
import { useNavigate, useParams } from "react-router";
import { isEmpty } from "../../functions/isEmpty";

export default function TextInput({
  isMobile,
  name,
  placeholder,
  value,
  setValue,
}) {
  let navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className="textinput">
      <div className="textinput_title">{name}</div>
      <input
        className="textinput_input"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;
