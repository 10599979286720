import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdCampaign,
  MdClose,
  MdFavoriteBorder,
  MdOutlineCampaign,
  MdPersonOutline,
  MdSearch,
} from "react-icons/md";

import "../../styles/Bottomnavbar.css";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { isEmpty } from "../../functions/isEmpty";
import { useLanguage } from "../../contexts/LanguageContext";
import texts from "../../texts/Texts";

export default function Bottomnavbar({ isMobile }) {
  let navigate = useNavigate();
  const location = useLocation();
  const { language, switchLanguage } = useLanguage();

  if (!isMobile) {
    return <></>;
  }

  return (
    <div className="bottomnavbar">
      <div className="bottomnavbar_buyer">
        <div
          className="bottomnavbar_buyer_navpoint_active"
          onClick={() => navigate("/")}>
          <SearchIconActive /> {texts[language].homeIcon}
        </div>
        <div className="bottomnavbar_buyer_navpoint">
          <FavoriteIcon /> {texts[language].favoritesIcon}
        </div>
        <div className="bottomnavbar_buyer_navpoint">
          <CampaignIcon /> {texts[language].orderIcon}
        </div>
        <div
          className="bottomnavbar_buyer_navpoint"
          onClick={() => navigate("/profile")}>
          <ProfileIcon /> {texts[language].profileIcon}
        </div>
      </div>
    </div>
  );
}

const PersonIcon = styled(MdPersonOutline)`
  font-size: 26px;
  color: #8f8f8f;
`;

const BackIcon = styled(MdArrowBackIos)`
  font-size: 27px;
  color: #f58700;
  margin-right: -15px;
`;

const CancelIcon = styled(MdClose)`
  font-size: 26px;
  color: #696969;
  margin-top: 3px;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 30px;
  margin-top: 5px;
  color: #b0b0b0;
`;

const FavoriteIcon = styled(MdFavoriteBorder)`
  font-size: 26px;
  margin-top: 7px;
  margin-bottom: 2px;
  color: #b0b0b0;
`;

const CampaignIcon = styled(MdOutlineCampaign)`
  font-size: 31px;
  margin-top: 4px;
  color: #b0b0b0;
`;

const ProfileIcon = styled(MdPersonOutline)`
  font-size: 27px;
  margin-top: 6px;
  color: #b0b0b0;
`;

/* ACTIVE */

const SearchIconActive = styled(MdSearch)`
  font-size: 31px;
  margin-top: 4px;
  color: #b0b0b0;
`;

const FavoriteIconActive = styled(MdFavoriteBorder)`
  font-size: 26px;
  margin-top: 7px;
  margin-bottom: 2px;
  color: #f58700;
`;

const CampaignIconActive = styled(MdOutlineCampaign)`
  font-size: 31px;
  margin-top: 4px;
  color: #f58700;
`;

const CampaignIconWhite = styled(MdCampaign)`
  font-size: 29px;
  margin-top: 2px;
  color: #ffffff;
`;

const ProfileIconActive = styled(MdPersonOutline)`
  font-size: 27px;
  margin-top: 6px;
  color: #f58700;
`;
