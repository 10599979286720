import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdCancel, MdMenu, MdOutlineImage } from "react-icons/md";

import "../styles/CreateService.css";
import TopNavbar from "../components/navbars/HomeTopNav";
import { ServicesBar } from "../components/cards/ServicesBar";
import { isEmpty } from "../functions/isEmpty";
import Spinner from "../components/CTA/Spinner";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import NumberInput from "../components/inputs/NumberInput";
import { useParams } from "react-router";

export default function CreateService({
  isMobile,
  apiURL,
  serviceCategories,
  authToken,
  companyData,
}) {
  const [imageUploads, setImageUploads] = useState([]);
  const [price, setPrice] = useState();
  const [isLoading, setIsLoading] = useState(0);
  const [publishChannel, setPublishChannel] = useState("");
  const [publishFormat, setPublishFormat] = useState("");
  const { id } = useParams();

  const insertLocalImage = (e) => {
    let file = e.target.files[0];
    let filetype = "image";

    if (isVideoFile(file)) {
      filetype = "video";
      console.log("Dateityp:", file);
    } else {
      console.log("Dateityp:", file);
    }

    setImageUploads({
      localURL: URL.createObjectURL(file),
      files: file,
      filetype: filetype,
    });
  };

  // upload image file
  const handleUploadForImage = async () => {
    setIsLoading(true);

    const files = document.getElementById("imgInp").files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    if (files.length != 0 && !isEmpty(imageUploads)) {
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => handleUpload(value));
    }
  };

  const findCategoryByName = (idToFind) => {
    const foundCategory = serviceCategories.find(
      (item) => item.id === idToFind
    );

    // Wenn ein Element mit der gegebenen ID gefunden wurde, gib das Element anhand des Keys "name" zurück
    if (foundCategory) {
      return foundCategory.name;
    }

    // Wenn keine Übereinstimmung gefunden wurde, gib null oder einen entsprechenden Wert zurück
    return null;
  };

  // upload profile data
  const handleUpload = async (imgURL, creationID, publishURL) => {
    setIsLoading(true);

    let serviceCategoryName = findCategoryByName(id);

    if (imgURL != "") {
      let images = [];

      images.unshift({ url: imgURL });
      let priceEuroCent = price * 100;
      const resa = await fetch(`${apiURL}/api/services/index.php`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          mediaFileURL: JSON.stringify(images),
          serviceCategory_id: id,
          serviceCategory_name: "Unboxing",
          companyname: companyData.name,
          companyID: companyData.id,
          publish_channel: publishChannel,
          publish_format: publishFormat,
          priceEuroCent: priceEuroCent,
        }),
      });

      await resa.text().then((value) => uploadEnds(value));
    }
  };

  // redirect
  const uploadEnds = (value) => {
    if (value != "") {
      //   setRefresh(!refresh);
      setImageUploads([]);
    }
    setIsLoading(false);
  };

  function isVideoFile(file) {
    const videoExtensions = ["mp4", "webm", "ogg", "avi", "mov", "mkv"];

    // Extrahiere die Dateiendung aus dem Dateinamen
    const fileExtension = file.name.split(".").pop().toLowerCase();

    // Überprüfe, ob die Dateiendung zu einer Videodatei gehört
    return videoExtensions.includes(fileExtension);
  }

  const handlePublishClick = (Channel, Format) => {
    setPublishChannel(Channel);
    setPublishFormat(Format);
  };

  return (
    <div className="createService">
      {!isEmpty(imageUploads) ? (
        <div className="">
          {imageUploads.filetype == "video" ? (
            <video
              className="createService_inputBtn_img"
              controls
              preload="metadata"
            >
              <source src={imageUploads.localURL} type="video/mp4" />
            </video>
          ) : (
            <img
              className="createService_inputBtn_img"
              src={imageUploads.localURL}
            />
          )}

          <div className="createService_inputBtn_cancelBtn">
            <CancelIcon onClick={() => setImageUploads([])} />
          </div>
        </div>
      ) : (
        <label htmlFor={"imgInp"} className="">
          <div className="createService_inputlabel">
            <ImageIcon /> Content hochladen
          </div>
        </label>
      )}
      <input
        type="file"
        accept="image/*, video/*"
        id={"imgInp"}
        name="files[]"
        className="createService_inputBtn d-none"
        onChange={(e) => insertLocalImage(e)}
      />
      Publishing
      <div className="createService_publishchannel">
        <div className="createService_publishchannel_box">
          <div className="createService_publishchannel_box_oneElement">
            Nur Content Creation. Kein Teilen aufm Kanal
          </div>
        </div>
        <div className="createService_publishchannel_box">
          <div
            className="createService_publishchannel_box_element"
            onClick={() => handlePublishClick("Instagram", "Reel")}
          >
            <InstagramIcon /> Video Reel
          </div>
          <div className="createService_publishchannel_box_element">
            <InstagramIcon /> Story
          </div>
          <div className="createService_publishchannel_box_element">
            <InstagramIcon /> Carousel Feed Post
          </div>
          <div className="createService_publishchannel_box_element">
            <InstagramIcon /> Photo Feed Post
          </div>
        </div>
        <div className="createService_publishchannel_box">
          <div className="createService_publishchannel_box_element">
            <TikTokIcon /> Video Reel
          </div>
          <div className="createService_publishchannel_box_element">
            <TikTokIcon />
            Story
          </div>
          <div className="createService_publishchannel_box_element">
            <TikTokIcon /> Carousel Reel
          </div>
          <div className="createService_publishchannel_box_element">
            <TikTokIcon /> Photo Reel
          </div>
        </div>
      </div>
      <div className="createService_price">
        <NumberInput
          name="Preis"
          placeholder="Preis eingeben"
          value={price}
          setValue={setPrice}
        />
      </div>
      {!isEmpty(imageUploads) && (
        <>
          {isLoading ? (
            <>
              <div className="createService_CTA">
                <Spinner type="white" />
              </div>
            </>
          ) : (
            <>
              <div
                className="createService_CTA"
                onClick={() => handleUploadForImage()}
              >
                Upload
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ImageIcon = styled(MdOutlineImage)`
  font-size: 30px;
  color: #474747;
`;

const CancelIcon = styled(MdCancel)`
  font-size: 25px;
  color: #575757;
`;

const InstagramIcon = styled(FaInstagram)`
  font-size: 25px;
  color: #575757;
`;

const TikTokIcon = styled(FaTiktok)`
  font-size: 22px;
  color: #575757;
`;
