import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdArrowBack, MdArrowBackIos, MdMenu } from "react-icons/md";

import "../styles/Checkout.css";
import TopNavbar from "../components/navbars/HomeTopNav";
import { ServicesBar } from "../components/cards/ServicesBar";
import { useNavigate } from "react-router";
import TextInput from "../components/inputs/TextInput";
import DefaultTopNav from "../components/navbars/DefaultTopNav";
import { isEmpty } from "../functions/isEmpty";

export default function Checkout({
  isMobile,
  apiURL,
  activeOrder,
  setActiveOrder,
  cart,
  setCart,
}) {
  const [page, setPage] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [streetname, setStreetname] = useState("");
  const [streetnumber, setStreetnumber] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [ccnumber, setCcnumber] = useState("");
  const [expireDateMonth, setExpireDateMonth] = useState("");
  const [expireDateYear, setExpireDateYear] = useState("");
  const [CVC, setCVC] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(activeOrder)) {
      navigate("/");
    }
  }, [activeOrder]);

  const handlePayButton = () => {
    let index = cart.indexOf(activeOrder);

    setCart((prevCart) => {
      // Make a shallow copy of the cart array
      const newCart = [...prevCart];

      // Check if the provided index is within the valid range
      if (index >= 0 && index < newCart.length) {
        // Set the "status" to the specified value for the specified index
        newCart[index].order.status = 1;
      }

      return newCart;
    });
    navigate("/cart");
  };

  return (
    <>
      {isMobile ? (
        <div className="checkout">
          <div className="defaulttopnav">
            <div
              className="defaulttopnav_backHitBox"
              onClick={() => navigate("/cart")}
            >
              <div className="defaulttopnav_backHitBox_backButton">
                <ArrowBack />
              </div>
              Back
            </div>
          </div>
          <div className="checkout">
            <div className="checkout_columns">
              <div className="checkout_columns_left">
                Billing
                <div className="checkout_columns_left_inputs">
                  <div className="checkout_columns_left_inputs_row">
                    <TextInput
                      name={"Companyname (optional)"}
                      value={companyname}
                      setValue={setCompanyname}
                      placeholder="enter companyname"
                    />
                  </div>
                  <div className="checkout_columns_left_inputs_row">
                    <TextInput
                      name={"Firstname"}
                      value={firstname}
                      setValue={setFirstname}
                      placeholder="enter firstname"
                    />
                    <TextInput
                      name={"Lastname"}
                      value={lastname}
                      setValue={setLastname}
                      placeholder="enter lastname"
                    />
                  </div>
                  <div className="checkout_columns_left_inputs_row">
                    <TextInput
                      name={"street"}
                      value={streetname}
                      setValue={setStreetname}
                      placeholder="enter streetname"
                    />
                    <TextInput
                      name={"streetnumber"}
                      value={streetnumber}
                      setValue={setStreetnumber}
                      placeholder="enter streetnumber"
                    />
                  </div>
                  <div className="checkout_columns_left_inputs_row">
                    <TextInput
                      name={"zip code"}
                      value={zip}
                      setValue={setZip}
                      placeholder="enter zip code"
                    />
                    <TextInput
                      name={"enter region"}
                      value={city}
                      setValue={setCity}
                      placeholder="enter region"
                    />
                  </div>
                </div>
              </div>
              <div className="checkout_columns_right">Payment</div>
            </div>
            <div className="checkout_row">
              <div className="checkout_cta" onClick={() => navigate("/")}>
                End
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="checkout">
          <div className="defaulttopnav">
            <div className="topnavbar_top">
              <div className="topnavbar_top_left">
                <div
                  className="topnavbar_top_left_logo"
                  onClick={() => navigate("/cart")}
                >
                  {/*
                <img
                src="../../../logo-desktop.png"
                className="topnavbar_top_left_logo"
              />
              */}
                  creator-ads
                </div>
              </div>
              <div className="topnavbar_top_navbar"></div>
            </div>
          </div>

          <div className="checkout_wrapper">
            <div className="padding-left">
              <div className="service_toprow_title">
                <div className="wrapper_left">
                  <div
                    className="creator_wrapper_one_button"
                    onClick={() => navigate("/cart")}
                  >
                    <ArrowBack />
                  </div>
                </div>
              </div>
            </div>

            <div className="checkout_left">
              <div className="checkout_left_left">
                <div className="checkout_columns">
                  <div className="checkout_columns_left">
                    Billing Data
                    <div className="checkout_columns_left_inputs">
                      <div className="checkout_columns_left_inputs_row">
                        <TextInput
                          name={"Companyname (optional)"}
                          value={companyname}
                          setValue={setCompanyname}
                          placeholder="Enter Companyname"
                        />
                      </div>
                      <div className="checkout_columns_left_inputs_row">
                        <TextInput
                          name={"Vorname"}
                          value={firstname}
                          setValue={setFirstname}
                          placeholder="Enter Firstname"
                        />
                        <TextInput
                          name={"Nachname"}
                          value={lastname}
                          setValue={setLastname}
                          placeholder="Enter Lastname"
                        />
                      </div>
                      <div className="checkout_columns_left_inputs_row">
                        <TextInput
                          name={"Straße"}
                          value={streetname}
                          setValue={setStreetname}
                          placeholder="Enter Streetname"
                        />
                        <TextInput
                          name={"Hausnummer"}
                          value={streetnumber}
                          setValue={setStreetnumber}
                          placeholder="Enter Streetnumber"
                        />
                      </div>
                      <div className="checkout_columns_left_inputs_row">
                        <TextInput
                          name={"Postleitzahl"}
                          value={zip}
                          setValue={setZip}
                          placeholder="Enter Zip"
                        />
                        <TextInput
                          name={"Stadt"}
                          value={city}
                          setValue={setCity}
                          placeholder="Enter City"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="checkout_columns_right">
                    Payment - Creditcard
                    <div className="checkout_columns_left_inputs_row">
                      <TextInput
                        name={"Creditcard Number"}
                        value={ccnumber}
                        setValue={setCcnumber}
                        placeholder="Enter Creditcard Number"
                      />
                    </div>
                    <div className="checkout_columns_left_inputs_row">
                      <TextInput
                        name={"Expire Month"}
                        value={expireDateMonth}
                        setValue={setExpireDateMonth}
                        placeholder="Expire Date"
                      />
                      <TextInput
                        name={"Expire Year"}
                        value={expireDateYear}
                        setValue={setExpireDateYear}
                        placeholder="Expire Year"
                      />
                      <TextInput
                        name={"CVC"}
                        value={CVC}
                        setValue={setCVC}
                        placeholder="CVC"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="checkout_left_right"></div>
            </div>

            <div className="checkout_right">
              <div className="checkout_right_title">Your Order</div>
              <div className="checkout_right_ad">
                <div className="checkout_right_ad_imagebox">
                  <img
                    src={
                      !isEmpty(activeOrder) &&
                      JSON.parse(activeOrder?.template?.stories)[0].url
                    }
                    className="checkout_right_ad_imagebox_img"
                  />
                </div>
                <div className="checkout_right_ad_name">
                  {activeOrder?.template?.title_en}
                </div>
              </div>
              <div className="checkout_right_creator">
                <div className="checkout_right_creator_imagebox">
                  <img
                    src={
                      !isEmpty(activeOrder) &&
                      JSON.parse(activeOrder?.creator?.pictures)[0].url
                    }
                    className="checkout_right_creator_imagebox_img"
                  />
                </div>
                <div className="checkout_right_creator_name">
                  {activeOrder?.creator?.companyname}
                </div>
              </div>
              <div className="checkout_right_pay">
                <div className="checkout_right_pay_price">250,00 €</div>
                <div className="checkout_cta" onClick={() => handlePayButton()}>
                  Pay
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: 3px;
  margin-left: 10px;
`;
