import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBack,
  MdArrowForward,
  MdAttachFile,
  MdCancel,
  MdMenu,
  MdMoreHoriz,
  MdOutlineImage,
} from "react-icons/md";

import { useNavigate, useParams } from "react-router";
import Card from "./cards/Card";

export default function MobileCreatorRow({
  isMobile,
  creators,
  filteredProduct,
  setActiveProductID,
  activeProductID,
}) {
  const [filteredCreators, setFilteredCreators] = useState([]);
  let navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    let newArray = [];
    creators.map((creator) => newArray.push(creator));
    // Iterate through each element in "creators"
    newArray = newArray.filter((creator) => {
      // Parse the "products" string into a JSON array
      let productsArray = JSON.parse(creator.products);

      // Check if the given number is present in any of the objects in the "products" array
      return productsArray.some(
        (product) => product == filteredProduct.internal_id
      );
    });

    setFilteredCreators(newArray);
  }, [filteredProduct, creators]);

  return (
    <div className="">
      <div className="home_productRow_title">{filteredProduct.title_en}</div>
      <div className="home_productRow">
        {filteredCreators.map((creator, index) => (
          <Card
            data={creator}
            setActiveProductID={setActiveProductID}
            givenProductIDForMobile={filteredProduct.internal_id}
            key={creator.id}
            isMobile={true}
          />
        ))}
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;
