import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBack,
  MdArrowBackIos,
  MdCampaign,
  MdCheck,
  MdDelete,
  MdLocalShipping,
  MdMenu,
  MdPlayArrow,
} from "react-icons/md";

import "../styles/Cart.css";
import TopNavbar from "../components/navbars/HomeTopNav";
import { ServicesBar } from "../components/cards/ServicesBar";
import { useNavigate } from "react-router";
import TextInput from "../components/inputs/TextInput";
import DefaultTopNav from "../components/navbars/DefaultTopNav";
import { InstagramEmbed } from "react-social-media-embed";
import { isEmpty } from "../functions/isEmpty";
import TextInputLarge from "../components/inputs/TextInputLarge";

export default function Cart({
  isMobile,
  apiURL,
  cart,
  activeCreator,
  setCart,
  activeOrder,
  setActiveOrder,
}) {
  const [page, setPage] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [streetname, setStreetname] = useState("");
  const [streetnumber, setStreetnumber] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");

  const [link, setLink] = useState("");

  function removeElementById(idToRemove) {
    const helperArray = [...cart];

    // Find the index of the element with the specified id
    const indexToRemove = helperArray.findIndex(
      (element) => element.id === idToRemove
    );

    // Check if the element with the specified id was found
    if (indexToRemove !== -1) {
      // Use splice to remove the element from the array
      helperArray.splice(indexToRemove, 1);
    }

    // Return the modified array
    setCart(helperArray);
  }

  let navigate = useNavigate();

  const incrementStatusByIndex = (index, incrementBy, newStatus) => {
    setCart((prevCart) => {
      // Make a shallow copy of the cart array
      const newCart = [...prevCart];

      // Check if the provided index is within the valid range
      if (index >= 0 && index < newCart.length) {
        // Set the "status" to the specified value for the specified index
        newCart[index].order.status = newStatus;
      }

      return newCart;
    });
  };

  const startPayment = (object) => {
    setActiveOrder(object);
    navigate("/checkout");
  };

  const openAd = () => {
    window
      .open("https://www.instagram.com/stories/cao_bai_bai/", "_blank")
      .focus();
  };

  return (
    <div>
      {isMobile ? (
        <div className="cart">
          <div className="defaulttopnav">
            <div
              className="defaulttopnav_backHitBox"
              onClick={() => navigate("/creator/" + activeCreator.id)}>
              <div className="defaulttopnav_backHitBox_backButton">
                <ArrowBack />
              </div>
              Back
            </div>
          </div>

          <div className="cart_progressbar">
            <div className="cart_progressbar_item">
              <div className="cart_progressbar_item_top">
                <StartIcon />
              </div>
              <div className="cart_progressbar_item_bottom">Starten</div>
            </div>
            <div className="cart_progressbar_divider"></div>
            <div className="cart_progressbar_item">
              <div className="cart_progressbar_item_top">
                <ShippingIcon />
              </div>
              <div className="cart_progressbar_item_bottom">Paketversand</div>
            </div>
            <div className="cart_progressbar_divider"></div>
            <div className="cart_progressbar_item">
              <div className="cart_progressbar_item_top">
                <CreationIcon />
              </div>
              <div className="cart_progressbar_item_bottom">Creation</div>
            </div>
            <div className="cart_progressbar_divider"></div>
            <div className="cart_progressbar_item">
              <div className="cart_progressbar_item_top">
                <AdIcon />
              </div>
              <div className="cart_progressbar_item_bottom">Live</div>
            </div>
          </div>
          <TextInputLarge
            isMobile={isMobile}
            name={"Additional Information"}
            placeholder={"Add Details to Your Order"}
            value={link}
            setValue={setLink}
          />
          {!isEmpty(cart) && (
            <div className="creator_bottom">
              <div className="creator_bottom_left">
                {!isEmpty(activeCreator)
                  ? cart.length * activeCreator.priceInstagramStory
                  : 0}
                ,00 €
              </div>
              <div className="creator_bottom_right">
                <div
                  className="creator_bottom_right_cta"
                  onClick={() => navigate("/checkout")}>
                  Next
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          {" "}
          <DefaultTopNav isMobile={isMobile} cart={cart} />
          <div className="cart">
            <div className="cart_title">Orders</div>
            <div className="cart_list">
              <div className="cart_list_titlerow">
                <div className="cart_list_titlerow_column">Werbung</div>
                <div className="cart_list_titlerow_column">Creator</div>
                <div className="cart_list_titlerow_column">Status</div>
              </div>
              {cart.map((item, index) => (
                <div className="cart_list_element">
                  <div className="cart_list_element_column">
                    <div className="cart_list_element_column_imagebox">
                      <img
                        src={JSON.parse(item.template.stories)[0].url}
                        className="cart_list_element_column_imagebox_img"
                      />
                    </div>
                    <div className="cart_list_element_column_text">
                      {item.template.title_en}
                    </div>
                  </div>
                  <div className="cart_list_element_column">
                    <div className="cart_list_element_column_imagebox">
                      <img
                        src={JSON.parse(item.creator.pictures)[0].url}
                        className="cart_list_element_column_imagebox_img border-round"
                      />
                    </div>
                    <div className="cart_list_element_column_text">
                      {item.creator.companyname}
                    </div>
                  </div>

                  <div className="cart_list_element_column">
                    <div className="">
                      {item.creator.priceInstagramStory + " €"}
                    </div>
                    {item.order.status == 0 && (
                      <div
                        className="cart_list_element_column_btn"
                        onClick={() => startPayment(item)}>
                        Pay and Start
                      </div>
                    )}
                    {item.order.status == 1 && (
                      <div
                        className="cart_list_element_column_inCreation"
                        onClick={() => incrementStatusByIndex(index, 1, 2)}>
                        In Creation ...
                      </div>
                    )}
                    {item.order.status >= 2 && (
                      <div
                        className="cart_list_element_column_Ready"
                        onClick={() => openAd()}>
                        Ready ! Click to View Ad
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

/*
NEXT: 
1. CSS "In Creation"
2. Auto Go Into the next state "Ready" after 5 seconds

*/

const PlayIcon = styled(MdPlayArrow)`
  font-size: 33px;
  color: #575757;
`;

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: 3px;
  margin-left: 10px;
`;

const DeleteIcon = styled(MdDelete)`
  font-size: 20px;
  color: #575757;
`;

const StartIcon = styled(MdCheck)`
  font-size: 20px;
  color: #575757;
`;

const ShippingIcon = styled(MdLocalShipping)`
  font-size: 20px;
  color: #575757;
`;

const CreationIcon = styled(MdPlayArrow)`
  font-size: 20px;
  color: #575757;
`;

const AdIcon = styled(MdCampaign)`
  font-size: 20px;
  color: #575757;
`;
