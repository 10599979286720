import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdArrowBackIos, MdMenu } from "react-icons/md";

import "../styles/Home.css";
import { ServicesBar } from "../components/cards/ServicesBar";
import { isEmpty } from "../functions/isEmpty";
import HomeTopNav from "../components/navbars/HomeTopNav";
import Card from "../components/cards/Card";
import { ProductData } from "../contexts/ProductData";
import DefaultTopNav from "../components/navbars/DefaultTopNav";
import MobileCreatorRow from "../components/MobileCreatorRow";

export default function Home({
  isMobile,
  apiURL,
  authToken,
  creators,
  templates,
  setActiveProductID,
  activeProductID,
  products,
  filterBranch,
  setFilterBranch,
  filterProductOrService,
  setFilterProductOrService,
  filterProductStart,
  setFilterProductStart,
  filterProductEnd,
  setFilterProductEnd,
  filteredCreators,
  cart,
}) {
  const [openFilter, setOpenFilter] = useState("");

  const handleClickBranch = (title, start, end) => {
    setFilterBranch(title);
    setFilterProductStart(start);
    setFilterProductEnd(end);
    setOpenFilter("");
  };

  return (
    <>
      {openFilter == "branch" && (
        <div className="home_filterpage">
          <div className="defaulttopnav">
            <div
              className="defaulttopnav_backHitBox"
              onClick={() => setOpenFilter("")}>
              <div className="defaulttopnav_backHitBox_backButton">
                <ArrowBack />
              </div>
              Back
            </div>
          </div>
          {filterBranch == "fashion" ? (
            <div className="home_filterpage_option_active">Fashion</div>
          ) : (
            <div
              className="home_filterpage_option"
              onClick={() => handleClickBranch("fashion", 1, 500)}>
              Fashion
            </div>
          )}
          {filterBranch == "beauty" ? (
            <div className="home_filterpage_option_active">Beauty</div>
          ) : (
            <div
              className="home_filterpage_option"
              onClick={() => handleClickBranch("beauty", 1001, 1500)}>
              Beauty
            </div>
          )}
          {filterBranch == "gaming" ? (
            <div className="home_filterpage_option_active">Gaming</div>
          ) : (
            <div
              className="home_filterpage_option"
              onClick={() => handleClickBranch("gaming", 2001, 2500)}>
              Gaming
            </div>
          )}
          {filterBranch == "finance" ? (
            <div className="home_filterpage_option_active">Finance</div>
          ) : (
            <div
              className="home_filterpage_option"
              onClick={() => handleClickBranch("finance", 3001, 3500)}>
              Finance
            </div>
          )}
          {filterBranch == "sport" ? (
            <div className="home_filterpage_option_active">Sports</div>
          ) : (
            <div
              className="home_filterpage_option"
              onClick={() => handleClickBranch("sport", 4001, 4500)}>
              Sports
            </div>
          )}
          {filterBranch == "food" ? (
            <div className="home_filterpage_option_active">Food</div>
          ) : (
            <div
              className="home_filterpage_option"
              onClick={() => handleClickBranch("food", 5001, 5500)}>
              Food
            </div>
          )}
          {filterBranch == "assets" ? (
            <div className="home_filterpage_option_active">Assets</div>
          ) : (
            <div
              className="home_filterpage_option"
              onClick={() => handleClickBranch("assets", 6001, 6500)}>
              Assets
            </div>
          )}
        </div>
      )}
      {openFilter == "" && (
        <div className="home">
          <HomeTopNav
            isMobile={isMobile}
            filterBranch={filterBranch}
            setFilterBranch={setFilterBranch}
            setFilterProductStart={setFilterProductStart}
            setFilterProductEnd={setFilterProductEnd}
            filterProductOrService={filterProductOrService}
            setFilterProductOrService={setFilterProductOrService}
            authToken={authToken}
            setOpenFilter={setOpenFilter}
            cart={cart}
          />
          {isMobile ? (
            <>
              {products
                .filter(
                  (product) =>
                    product.internal_id >=
                      (filterProductOrService == "products"
                        ? filterProductStart
                        : filterProductStart + 0) &&
                    product.internal_id <=
                      (filterProductOrService == "products"
                        ? filterProductEnd
                        : filterProductEnd + 500)
                )
                .map((filteredProduct) => (
                  <MobileCreatorRow
                    creators={creators}
                    filteredProduct={filteredProduct}
                    setActiveProductID={setActiveProductID}
                    activeProductID={activeProductID}
                  />
                ))}
            </>
          ) : (
            <div className="homewrapper">
              <div className="homewrapper_left">
                <div className="homewrapper_filterbox">
                  <div className="homewrapper_filterbox_title">Ads for</div>
                  {filterProductOrService == "products" ? (
                    <>
                      <div className="homewrapper_filterbox_option_active">
                        Products
                      </div>
                      <div
                        className="homewrapper_filterbox_option"
                        onClick={() => setFilterProductOrService("services")}>
                        Services
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="homewrapper_filterbox_option"
                        onClick={() => setFilterProductOrService("products")}>
                        Products
                      </div>
                      <div className="homewrapper_filterbox_option_active">
                        Services
                      </div>
                    </>
                  )}
                </div>
                <div className="homewrapper_filterbox">
                  <div className="homewrapper_filterbox_title">
                    {filterProductOrService == "products"
                      ? "Your Product"
                      : "Your Service"}
                  </div>
                  {products
                    .filter(
                      (product) =>
                        product.internal_id >=
                          (filterProductOrService == "products"
                            ? filterProductStart
                            : filterProductStart + 500) &&
                        product.internal_id <=
                          (filterProductOrService == "products"
                            ? filterProductEnd
                            : filterProductEnd + 500)
                    )
                    .map((filteredProduct) => (
                      <>
                        {activeProductID == filteredProduct.internal_id ? (
                          <div
                            className="homewrapper_filterbox_option_active"
                            key={filteredProduct.id}
                            onClick={() =>
                              setActiveProductID(filteredProduct.internal_id)
                            }>
                            {filteredProduct.title_en}
                          </div>
                        ) : (
                          <div
                            className="homewrapper_filterbox_option"
                            key={filteredProduct.id}
                            onClick={() =>
                              setActiveProductID(filteredProduct.internal_id)
                            }>
                            {filteredProduct.title_en}
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
              <div>
                <div className="home_templates">
                  <div className="bold" style={{ display: "inline-block" }}>
                    Creators
                  </div>{" "}
                </div>
                <div className="homewrapper_right">
                  {filteredCreators.map((creator, index) => (
                    <Card
                      data={creator}
                      setActiveProductID={setActiveProductID}
                      key={creator.id}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;
