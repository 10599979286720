import { useState, useEffect } from "react";
import { isEmpty } from "./isEmpty";

const useFetch = (
  apiUrl,
  authToken,
  method = "GET",
  body = null,
  dependencies = []
) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const headers = {
          Authorization: authToken ? `Bearer ${authToken.JWT}` : "",
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        };

        const requestOptions = {
          method,
          headers,
          body: body ? JSON.stringify(body) : null,
        };

        const res = await fetch(apiUrl, requestOptions);

        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }

        const value = await res.text();
        if (!isEmpty(value)) {
          const parsedValue = JSON.parse(value);
          setData(parsedValue);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [...dependencies, apiUrl, authToken, method, body]);

  return { data, loading, error };
};

export default useFetch;
