const texts = {
  en: {
    searchbar: "Content Creation",
    homeIcon: "Home",
    favoritesIcon: "Favorites",
    orderIcon: "Orders",
    profileIcon: "Profile",
    fashionIcon: "Fashion",
    beautyIcon: "Beauty",
    businessIcon: "Finance",
    sportsIcon: "Sports",
    gamingIcon: "Gaming",
    foodIcon: "Food",
    familyIcon: "Family",
    assetIcon: "Assets",
  },
  de: {
    searchbar: "Content Creation",
    homeIcon: "Home",
    favoritesIcon: "Favoriten",
    orderIcon: "Bestellungen",
    profileIcon: "Profil",
    fashionIcon: "Mode",
    beautyIcon: "Beauty",
    businessIcon: "Finanzen",
    sportsIcon: "Sport",
    gamingIcon: "Gaming",
    foodIcon: "Essen",
    familyIcon: "Familie",
    assetIcon: "Wertanlagen",
  },
  // Add more languages as needed
};

export default texts;
