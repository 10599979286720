import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdAdd,
  MdAddCircle,
  MdAddCircleOutline,
  MdArrowBack,
  MdArrowBackIos,
  MdClose,
  MdDelete,
  MdMenu,
  MdOutlinePerson,
  MdPerson2,
  MdPerson3,
  MdPersonOutline,
  MdPersonPinCircle,
  MdPlayArrow,
} from "react-icons/md";

import "../styles/Creator.css";
import TopNavbar from "../components/navbars/HomeTopNav";
import { ServicesBar } from "../components/cards/ServicesBar";
import { FaInstagram } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import MediaPlayer from "../components/mediaPlayer/MediaPlayer";
import { isEmpty } from "../functions/isEmpty";
import DefaultTopNav from "../components/navbars/DefaultTopNav";
import { ProductData } from "../contexts/ProductData";

export default function Creator({
  isMobile,
  apiURL,
  authToken,
  services,
  creators,
  activeProductID,
  cart,
  setCart,
  setGlobalActiveCreator,
  templates,
}) {
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [activeTemplateID, setActiveTemplateID] = useState(0);
  const [activeCreatorImg, setActiveCreatorImg] = useState("");
  const [creatorData, setCreatorData] = useState([]);
  let navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!isEmpty(creators)) {
      let creator = creators.find((element) => element.id === id);
      setCreatorData(creator);
      setActiveCreatorImg(JSON.parse(creator.pictures)[0].url);
    }
  }, [creators]);

  useEffect(() => {
    if (!isEmpty(templates) && !isEmpty(creators)) {
      // 1. filter only ads for the product

      let helpArray = templates;
      let newArray = [];
      for (let i = 0; i < helpArray.length; i++) {
        if (helpArray[i].product_internal_id == activeProductID) {
          newArray.push(helpArray[i]);
        }
      }

      // 2. filter only ads the creator has approved on the base of previous filtered in step 1
      let newArray2 = [];
      newArray.map((template) => newArray2.push(template));

      let creator = creators.find((element) => element.id === id);

      let adsArray = JSON.parse(creator.ads);

      // Filter the "templates" array based on the matching "category" values
      newArray2 = newArray2.filter((template) => {
        return adsArray.includes(template.category);
      });

      setFilteredTemplates(newArray2);
    }
  }, [templates, creators]);

  const addToCart = (template) => {
    let newArray = [];
    cart.map((object) => newArray.push(object));

    let creator = creators.find((element) => element.id === id);
    let order = { id: 1, status: 0 };
    let newObject = { creator: creator, template: template, order: order };
    newArray.push(newObject);

    setCart(newArray);
  };

  const openInstagramProfil = () => {
    window.open(creatorData.instagramProfileURL, "_blank").focus();
  };

  return (
    <>
      {isMobile ? (
        <div className="creator">
          <div className="creator_wrapper">
            <div className="close_btn" onClick={() => navigate("/")}>
              <CloseIcon />
            </div>
            <div className="creator_left">
              <div className="creator_left_imgbox">
                <img
                  src={!isEmpty(creatorData) ? activeCreatorImg : ""}
                  className="creator_left_imgbox_img"
                />
              </div>
              <div className="creator_left_moreimages">
                {!isEmpty(creatorData) &&
                  JSON.parse(creatorData.pictures).map((pic) => (
                    <>
                      {activeCreatorImg == pic.url ? (
                        <img
                          src={pic.url}
                          className="creator_left_moreimages_img_active"
                          onClick={() => setActiveCreatorImg(pic.url)}
                        />
                      ) : (
                        <img
                          src={pic.url}
                          className="creator_left_moreimages_img"
                          onClick={() => setActiveCreatorImg(pic.url)}
                        />
                      )}
                    </>
                  ))}
              </div>
              <div className="creator_left_descriptionbox">
                <div className="creator_left_descriptionbox_name">
                  {creatorData.companyname}
                </div>
                <div className="creator_left_descriptionbox_follower">
                  Follower: {creatorData.followerInstagram}
                </div>
              </div>
              <div
                className="creator_left_openInstaProfile"
                onClick={() => openInstagramProfil()}
              >
                <InstagramIcon /> Instagram Profil öffnen
              </div>
              {/*
      <iframe
            className="creator_instagram_iframe"
            src="https://www.instagram.com/portraitperspektive/embed"
            scrolling="no"
            frameBorder="no"
          ></iframe>
            */}
            </div>
            <div className="creator_right">
              <div className="creator_right_title">
                Werbungen die {creatorData.companyname} erstellen kann
              </div>
              <div className="creator_right_servicelist">
                {filteredTemplates.map((template) => (
                  <>
                    {activeTemplateID == template.id ? (
                      <div className="creator_right_servicelist_service_active">
                        <div className="creator_right_servicelist_service_active_top">
                          <div className="creator_right_servicelist_service_left">
                            <img
                              src={JSON.parse(template.stories)[0].url}
                              className="creator_right_servicelist_service_left_img"
                            />
                            <div className="creator_right_servicelist_service_left_text">
                              <div className="creator_right_servicelist_service_left_text_top">
                                {template.title_en}
                              </div>
                              <div className="creator_right_servicelist_service_left_text_bottom"></div>
                            </div>
                          </div>
                          <div className="creator_right_servicelist_service_right">
                            <div
                              className="creator_right_servicelist_service_right_addButton"
                              onClick={() => addToCart(template)}
                            >
                              + Add to Cart
                            </div>
                          </div>
                        </div>
                        <div className="creator_right_servicelist_service_active_bottom">
                          {JSON.parse(template.stories).map((story, index) => (
                            <div className="creator_right_servicelist_service_active_bottom_element">
                              <div className="creator_right_servicelist_service_active_bottom_element_left">
                                <img
                                  src={story.url}
                                  className="creator_right_servicelist_service_active_bottom_element_img"
                                />
                              </div>
                              <div className="creator_right_servicelist_service_active_bottom_element_right">
                                {index + 1 + ". " + story.description}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="creator_right_servicelist_service"
                        onClick={() => setActiveTemplateID(template.id)}
                      >
                        <div className="creator_right_servicelist_service_left">
                          <img
                            src={JSON.parse(template.stories)[0].url}
                            className="creator_right_servicelist_service_left_img"
                          />
                          <div className="creator_right_servicelist_service_left_text">
                            <div className="creator_right_servicelist_service_left_text_top">
                              {template.title_en}
                            </div>
                            <div className="creator_right_servicelist_service_left_text_bottom"></div>
                          </div>
                        </div>
                        <div className="creator_right_servicelist_service_right">
                          <div
                            className="creator_right_servicelist_service_right_addButton"
                            onClick={() => addToCart(template)}
                          >
                            + Add to Cart
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="creator">
          <DefaultTopNav isMobile={isMobile} cart={cart} />
          <div className="creator_wrapper">
            <div className="close_btn" onClick={() => navigate("/")}>
              <CloseIcon />
            </div>
            <div className="creator_left">
              <div className="creator_left_imgbox">
                <img
                  src={!isEmpty(creatorData) ? activeCreatorImg : ""}
                  className="creator_left_imgbox_img"
                />
              </div>
              <div className="creator_left_moreimages">
                {!isEmpty(creatorData) &&
                  JSON.parse(creatorData.pictures).map((pic) => (
                    <>
                      {activeCreatorImg == pic.url ? (
                        <img
                          src={pic.url}
                          className="creator_left_moreimages_img_active"
                          onClick={() => setActiveCreatorImg(pic.url)}
                        />
                      ) : (
                        <img
                          src={pic.url}
                          className="creator_left_moreimages_img"
                          onClick={() => setActiveCreatorImg(pic.url)}
                        />
                      )}
                    </>
                  ))}
              </div>
              <div className="creator_left_descriptionbox">
                <div className="creator_left_descriptionbox_name">
                  {creatorData.companyname}
                </div>
                <div className="creator_left_descriptionbox_follower">
                  Follower: {creatorData.followerInstagram}
                </div>
              </div>
              <div
                className="creator_left_openInstaProfile"
                onClick={() => openInstagramProfil()}
              >
                <InstagramIcon /> Instagram Profil öffnen
              </div>
              {/*
      <iframe
            className="creator_instagram_iframe"
            src="https://www.instagram.com/portraitperspektive/embed"
            scrolling="no"
            frameBorder="no"
          ></iframe>
            */}
            </div>
            <div className="creator_right">
              <div className="creator_right_title">
                Werbungen die {creatorData.companyname} erstellen kann
              </div>
              <div className="creator_right_servicelist">
                {filteredTemplates.map((template) => (
                  <>
                    {activeTemplateID == template.id ? (
                      <div className="creator_right_servicelist_service_active">
                        <div className="creator_right_servicelist_service_active_top">
                          <div
                            className="creator_right_servicelist_service_left"
                            onClick={() => setActiveTemplateID(0)}
                          >
                            <img
                              src={JSON.parse(template.stories)[0].url}
                              className="creator_right_servicelist_service_left_img"
                            />
                            <div className="creator_right_servicelist_service_left_text">
                              <div className="creator_right_servicelist_service_left_text_top">
                                {template.title_en}
                              </div>
                              <div className="creator_right_servicelist_service_left_text_bottom"></div>
                            </div>
                            <ArrowTopIcon />
                          </div>
                          <div className="creator_right_servicelist_service_right">
                            <div
                              className="creator_right_servicelist_service_right_addButton"
                              onClick={() => addToCart(template)}
                            >
                              + Add to Cart
                            </div>
                          </div>
                        </div>
                        <div className="creator_right_servicelist_service_active_bottom">
                          {JSON.parse(template.stories).map((story, index) => (
                            <div className="creator_right_servicelist_service_active_bottom_element">
                              <div className="creator_right_servicelist_service_active_bottom_element_left">
                                <img
                                  src={story.url}
                                  className="creator_right_servicelist_service_active_bottom_element_img"
                                />
                              </div>
                              <div className="creator_right_servicelist_service_active_bottom_element_right">
                                {index + 1 + ". " + story.description}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="creator_right_servicelist_service"
                        onClick={() => setActiveTemplateID(template.id)}
                      >
                        <div className="creator_right_servicelist_service_left">
                          <img
                            src={JSON.parse(template.stories)[0].url}
                            className="creator_right_servicelist_service_left_img"
                          />
                          <div className="creator_right_servicelist_service_left_text">
                            <div className="creator_right_servicelist_service_left_text_top">
                              {template.title_en}
                            </div>
                            <div className="creator_right_servicelist_service_left_text_bottom"></div>
                          </div>
                          <ArrowBottomIcon />
                        </div>
                        <div className="creator_right_servicelist_service_right">
                          <div
                            className="creator_right_servicelist_service_right_addButton"
                            onClick={() => addToCart(template)}
                          >
                            + Add to Cart
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const DeleteIcon = styled(MdDelete)`
  font-size: 20px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: 3px;
  margin-left: 10px;
`;

const ArrowTopIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  transform: rotateY(180deg) rotate(90deg);
  margin-bottom: -10px;
  margin-left: 10px;
`;

const ArrowBottomIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  transform: rotateY(180deg) rotate(-90deg);
  margin-bottom: 10px;
  margin-left: 10px;
`;

const AddIcon = styled(MdAdd)`
  font-size: 38px;
  color: #ffffff;
  margin-top: 4px;
`;

const RoundAddIcon = styled(MdAddCircle)`
  font-size: 36px;
  color: #f58700;
  margin-top: 4px;
`;

const InstagramIcon = styled(FaInstagram)`
  font-size: 26px;
  color: #575757;
`;

const PlayIcon = styled(MdPlayArrow)`
  font-size: 33px;
  color: #575757;
`;

const PersonIcon = styled(MdOutlinePerson)`
  font-size: 33px;
  color: #575757;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #575757;
`;
