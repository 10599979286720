import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdAdd,
  MdAddCircle,
  MdAddCircleOutline,
  MdArrowBack,
  MdArrowBackIos,
  MdClose,
  MdDelete,
  MdEdit,
  MdHome,
  MdMenu,
  MdOutlinePerson,
  MdPause,
  MdPerson2,
  MdPerson3,
  MdPersonOutline,
  MdPersonPinCircle,
  MdPlayArrow,
  MdPlayCircleOutline,
} from "react-icons/md";

import "../styles/Service.css";
import { FaInstagram } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import MediaPlayer from "../components/mediaPlayer/MediaPlayer";
import { isEmpty } from "../functions/isEmpty";
import DefaultTopNav from "../components/navbars/DefaultTopNav";

export default function Service({ isMobile, apiURL, creators, templates }) {
  const [activePage, setActivePage] = useState("overview"); // overview, ad, creator, company
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const [activeVideo, setActiveVideo] = useState({ url: "" });
  const [videos, setVideos] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [creatorPicked, setCreatorPicked] = useState([]);
  const [companyname, setCompanyname] = useState("");

  let navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!isEmpty(templates)) {
      for (let i = 0; i < templates.length; i++) {
        if (id == templates[i].id) {
          setVideos(JSON.parse(templates[i].videos));
          setTemplateData(templates[i]);
        }
      }
    }
  }, [templates]);

  useEffect(() => {
    if (!isEmpty(creators)) {
      setCreatorPicked(creators[0]);
    }
  }, [creators]);

  useEffect(() => {
    if (!isEmpty(videos)) {
      handleClick(videos[0]);
    }
  }, [videos]);

  function increaseWidth(elementId) {
    const element = document.getElementById(elementId);

    let currentWidth = 1; // starting width in percentage
    const increment = 1; // increment percentage

    const intervalId = setInterval(() => {
      if (currentWidth >= 100) {
        clearInterval(intervalId); // stop when width reaches 100%
      } else {
        currentWidth += increment;
        element.style.width = currentWidth + "%";
      }
    }, 100); // interval in milliseconds (0.1 second)

    // Return the intervalId so it can be used to stop the interval later
    return intervalId;
  }

  function clearAllIntervals() {
    // Clear all intervals by iterating through the stored IDs
    for (let i = 0; i < 999; i++) {
      clearInterval([i]);
    }
  }

  const handleClick = (videoObject) => {
    setActiveVideo(videoObject);
    for (let i = 0; i < videos.length; i++) {
      document.getElementById("video" + i).style.width = 0 + "%";
    }
    clearAllIntervals();

    let progressBarId;
    for (let i = 0; i < videos.length; i++) {
      if (videoObject.url == videos[i].url) {
        progressBarId = "video" + i;
      }
    }

    increaseWidth(progressBarId);
  };

  const nextVideo = () => {
    setIsVideoPlaying(true);
    //wenn letztes video im array, dann von vorne, ansonsten weiter
    for (let i = 0; i < videos.length; i++) {
      if (activeVideo.url == videos[i].url) {
        if (i + 1 != videos.length) {
          handleClick(videos[i + 1]);
        } else {
          handleClick(videos[0]);
        }
      }
    }
  };

  const clickPlayPauseButton = () => {
    let videoPlayer = document.getElementById("mediaPlayer");
    if (isVideoPlaying) {
      videoPlayer.pause();
      setIsVideoPlaying(false);
    } else {
      videoPlayer.play();
      setIsVideoPlaying(true);
    }
  };

  // ich hab ein activeVideo state. ansonsten nur ein

  useEffect(() => {
    var video = document.querySelector("mediaPlayer");

    if (video != null) {
      document.addEventListener("DOMContentLoaded", function () {
        var video = document.querySelector("mediaPlayer");

        video.addEventListener("webkitbeginfullscreen", function () {
          video.webkitEnterFullscreen(); // Call this to exit fullscreen
        });
      });
    }
  }, []);

  return (
    <div>
      {isMobile ? (
        <div className="service">
          <div className="service_preview">
            <div className="service_preview_topbar">
              <div className="service_preview_topbar_progressbar">
                {videos.map((video, index) => (
                  <div className="service_preview_topbar_progressbar_bar">
                    <div
                      className="service_preview_topbar_progressbar_bar_progress"
                      id={"video" + index}></div>
                  </div>
                ))}
              </div>
              <div className="service_preview_topbar_bottombar">
                <img
                  src={
                    !isEmpty(creatorPicked)
                      ? JSON.parse(creatorPicked.pictures)[0].url
                      : ""
                  }
                  className="service_preview_topbar_img"
                />
                <div className="service_preview_topbar_text">
                  <div className="service_preview_topbar_text_top">
                    Instagram Story
                  </div>
                  <div className="service_preview_topbar_text_bottom">
                    {creatorPicked.companyname}
                  </div>
                </div>
                <div className="service_closeBtn" onClick={() => navigate("/")}>
                  <CloseIcon />
                </div>
              </div>
            </div>
            <div
              className="service_preview_mediaplayerWrapper"
              onClick={() => nextVideo()}>
              <video
                playsinline
                controls={false}
                preload="metadata"
                className={"service_preview_img"}
                id={"mediaPlayer"}
                key={activeVideo.url}
                autoPlay={true}
                onEnded={() => nextVideo()}>
                <source src={activeVideo.url + "#t=0.01"} type={`video/mp4`} />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="service_preview_bottombar">
              <div
                className="service_preview_bottombar_playbutton"
                id={"playButton"}
                onClick={() => clickPlayPauseButton()}>
                {isVideoPlaying ? <PauseIcon /> : <PlayIcon />}
              </div>
            </div>
          </div>
          <div className="service_details_rows">
            <div className="service_details_topbar">
              <div className="service_details_title">
                Content Creation & Instagram Story Post
              </div>
              <div className="service_details_savebutton">add to your ads</div>
            </div>

            <div className="service_overviewbar">
              <div className="service_overviewbar_box">
                <div className="service_overviewbar_box_title">You get</div>
                <div className="service_overviewbar_box_element">
                  <div className="service_overviewbar_box_element_left">
                    <VideoIcon />
                  </div>
                  <div className="service_overviewbar_box_element_right">
                    {videos.length} Videos
                  </div>
                </div>
                <div className="service_overviewbar_box_title">Posted on</div>
                <div className="service_overviewbar_box_element">
                  <div className="service_overviewbar_box_element_left">
                    <InstagramIcon />
                  </div>
                  <div className="service_overviewbar_box_element_right">
                    Instagram Story
                  </div>
                </div>
              </div>
              <div className="service_overviewbar_box">
                <div className="service_overviewbar_box_title">You reach</div>
                <div className="service_overviewbar_box_element">
                  <div className="service_overviewbar_box_element_left">
                    <img
                      src={
                        !isEmpty(creatorPicked)
                          ? JSON.parse(creatorPicked.pictures)[0].url
                          : ""
                      }
                      className="service_overviewbar_box_element_left_img"
                    />
                  </div>
                  <div className="service_overviewbar_box_element_right">
                    {creatorPicked.followerInstagram} Followers
                  </div>
                </div>
              </div>
              <div className="service_overviewbar_box">
                <div className="service_overviewbar_box_title">Price</div>
                <div className="service_overviewbar_box_element">
                  <div className="service_overviewbar_box_element_right">
                    {videos.length},00€
                  </div>
                </div>
              </div>
            </div>
            <div className="service_overviewbar_box_title">Customization</div>
            <div
              className="service_details_rows_row"
              onClick={() => setActivePage("creator")}>
              <div className="service_details_rows_row_left">
                <img
                  src={
                    !isEmpty(creatorPicked)
                      ? JSON.parse(creatorPicked.pictures)[0].url
                      : ""
                  }
                  className="service_details_rows_row_left_img"
                />
              </div>
              <div className="service_details_rows_row_middle">
                <div className="service_details_rows_row_middle_top">
                  <div className="bold"> Creator </div> Creates and posts the
                  videos for you
                </div>
                <div className="service_details_rows_row_middle_bottom">
                  {creatorPicked.companyname}, {creatorPicked.followerInstagram}{" "}
                  Follower
                </div>
              </div>
              <div className="service_details_rows_row_right">
                <EditIcon />
              </div>
            </div>
            <div
              className="service_details_rows_row"
              onClick={() => setActivePage("company")}>
              <div className="service_details_rows_row_left">
                <HomeIcon />
              </div>
              <div className="service_details_rows_row_middle">
                <div className="service_details_rows_row_middle_top">
                  <div className="bold"> Your Business </div> Potencial
                  Customers find you here
                </div>
                <div className="service_details_rows_row_middle_bottom">
                  {companyname},
                </div>
              </div>
              <div className="service_details_rows_row_right">
                <EditIcon />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="service">
          <DefaultTopNav isMobile={isMobile} />
          <div className="service_flexwrapper">
            <div className="service_closeBtn" onClick={() => navigate("/")}>
              <CloseIcon />
            </div>
            <div className="service_preview">
              <div className="service_preview_topbar">
                <div className="service_preview_topbar_progressbar">
                  {videos.map((video, index) => (
                    <div className="service_preview_topbar_progressbar_bar">
                      <div
                        className="service_preview_topbar_progressbar_bar_progress"
                        id={"video" + index}></div>
                    </div>
                  ))}
                </div>
                <div className="service_preview_topbar_bottombar">
                  <img
                    src={
                      !isEmpty(creatorPicked)
                        ? JSON.parse(creatorPicked.pictures)[0].url
                        : ""
                    }
                    className="service_preview_topbar_img"
                  />
                  <div className="service_preview_topbar_text">
                    <div className="service_preview_topbar_text_top">
                      Instagram Story
                    </div>
                    <div className="service_preview_topbar_text_bottom">
                      {creatorPicked.companyname}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="service_preview_mediaplayerWrapper"
                onClick={() => nextVideo()}>
                <video
                  controls={false}
                  preload="metadata"
                  className={"service_preview_img"}
                  id={"mediaPlayer"}
                  key={activeVideo.url}
                  autoPlay={true}
                  onEnded={() => nextVideo()}>
                  <source
                    src={activeVideo.url + "#t=0.01"}
                    type={`video/mp4`}
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="service_preview_bottombar">
                <div
                  className="service_preview_bottombar_playbutton"
                  id={"playButton"}
                  onClick={() => clickPlayPauseButton()}>
                  {isVideoPlaying ? <PauseIcon /> : <PlayIcon />}
                </div>
              </div>
            </div>
            <div className="service_details">
              {activePage == "creator" && (
                <div className="service_pickCreator">
                  <div className="service_pickCreator_topBar">
                    <div className="service_pickCreator_topBar_title">
                      Wähle deinen Creator
                    </div>
                    <div
                      className="service_pickCreator_topBar_Save"
                      onClick={() => setActivePage("overview")}>
                      Speichern
                    </div>
                  </div>
                  <div className="service_pickCreator_creators">
                    {creators.map((creator) =>
                      creatorPicked.id == creator.id ? (
                        <div className="service_pickCreator_creators_creator_active">
                          <div className="service_pickCreator_creators_creator_top">
                            <img
                              src={
                                !isEmpty(creator.pictures)
                                  ? JSON.parse(creator.pictures)[0].url
                                  : ""
                              }
                              className="service_pickCreator_creators_creator_top_img"
                            />
                          </div>
                          <div className="service_pickCreator_creators_creator_bottom">
                            {creator.companyname} <br />
                            {creator.followerInstagram} Follower
                          </div>
                        </div>
                      ) : (
                        <div
                          className="service_pickCreator_creators_creator"
                          onClick={() => setCreatorPicked(creator)}>
                          <div className="service_pickCreator_creators_creator_top">
                            <img
                              src={
                                !isEmpty(creator.pictures)
                                  ? JSON.parse(creator.pictures)[0].url
                                  : ""
                              }
                              className="service_pickCreator_creators_creator_top_img"
                            />
                          </div>
                          <div className="service_pickCreator_creators_creator_bottom">
                            {creator.companyname} <br />
                            {creator.followerInstagram} Follower
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
              {activePage == "company" && (
                <div className="service_companyInputs">
                  <div className="service_pickCreator_topBar">
                    <div className="service_pickCreator_topBar_title">
                      Wähle deinen Creator
                    </div>
                    <div
                      className="service_pickCreator_topBar_Save"
                      onClick={() => setActivePage("overview")}>
                      Speichern
                    </div>
                  </div>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Deinen Firmennamen"
                    value={companyname}
                    onChange={(e) => {
                      setCompanyname(e.target.value);
                    }}
                  />
                </div>
              )}
              {activePage == "overview" && (
                <div>
                  <div className="service_details_rows">
                    <div className="service_details_topbar">
                      <div className="service_details_title">
                        Content Creation & Instagram Story Post
                      </div>
                      <div className="service_details_savebutton">
                        add to your ads
                      </div>
                    </div>

                    <div className="service_overviewbar">
                      <div className="service_overviewbar_box">
                        <div className="service_overviewbar_box_title">
                          You get
                        </div>
                        <div className="service_overviewbar_box_element">
                          <div className="service_overviewbar_box_element_left">
                            <VideoIcon />
                          </div>
                          <div className="service_overviewbar_box_element_right">
                            {videos.length} Videos
                          </div>
                        </div>
                        <div className="service_overviewbar_box_title">
                          Posted on
                        </div>
                        <div className="service_overviewbar_box_element">
                          <div className="service_overviewbar_box_element_left">
                            <InstagramIcon />
                          </div>
                          <div className="service_overviewbar_box_element_right">
                            Instagram Story
                          </div>
                        </div>
                      </div>
                      <div className="service_overviewbar_box">
                        <div className="service_overviewbar_box_title">
                          You reach
                        </div>
                        <div className="service_overviewbar_box_element">
                          <div className="service_overviewbar_box_element_left">
                            <img
                              src={
                                !isEmpty(creatorPicked)
                                  ? JSON.parse(creatorPicked.pictures)[0].url
                                  : ""
                              }
                              className="service_overviewbar_box_element_left_img"
                            />
                          </div>
                          <div className="service_overviewbar_box_element_right">
                            {creatorPicked.followerInstagram} Followers
                          </div>
                        </div>
                      </div>
                      <div className="service_overviewbar_box">
                        <div className="service_overviewbar_box_title">
                          Price
                        </div>
                        <div className="service_overviewbar_box_element">
                          <div className="service_overviewbar_box_element_right">
                            {videos.length},00€
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service_overviewbar_box_title">
                      Customization
                    </div>
                    <div
                      className="service_details_rows_row"
                      onClick={() => setActivePage("creator")}>
                      <div className="service_details_rows_row_left">
                        <img
                          src={
                            !isEmpty(creatorPicked)
                              ? JSON.parse(creatorPicked.pictures)[0].url
                              : ""
                          }
                          className="service_details_rows_row_left_img"
                        />
                      </div>
                      <div className="service_details_rows_row_middle">
                        <div className="service_details_rows_row_middle_top">
                          <div className="bold"> Creator </div> Creates and
                          posts the videos for you
                        </div>
                        <div className="service_details_rows_row_middle_bottom">
                          {creatorPicked.companyname},{" "}
                          {creatorPicked.followerInstagram} Follower
                        </div>
                      </div>
                      <div className="service_details_rows_row_right">
                        <EditIcon />
                      </div>
                    </div>
                    <div
                      className="service_details_rows_row"
                      onClick={() => setActivePage("company")}>
                      <div className="service_details_rows_row_left">
                        <HomeIcon />
                      </div>
                      <div className="service_details_rows_row_middle">
                        <div className="service_details_rows_row_middle_top">
                          <div className="bold"> Your Business </div> Potencial
                          Customers find you here
                        </div>
                        <div className="service_details_rows_row_middle_bottom">
                          {companyname},
                        </div>
                      </div>
                      <div className="service_details_rows_row_right">
                        <EditIcon />
                      </div>
                    </div>
                  </div>

                  <div className="service_details_descriptiveText"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const DeleteIcon = styled(MdDelete)`
  font-size: 20px;
  color: #575757;
`;

const CloseIcon = styled(MdClose)`
  font-size: 20px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: 3px;
  margin-left: 10px;
`;

const EditIcon = styled(MdEdit)`
  font-size: 38px;
  color: #000000;
  margin-top: 4px;
`;

const HomeIcon = styled(MdHome)`
  font-size: 45px;
  color: #000000;
  margin-left: 7px;
  margin-right: 8px;
`;

const VideoIcon = styled(MdPlayCircleOutline)`
  font-size: 43px;
  color: #222222;
`;

const InstagramIcon = styled(FaInstagram)`
  font-size: 40px;
  color: #222222;
`;

const PlayIcon = styled(MdPlayArrow)`
  font-size: 36px;
  color: #222222;
`;

const PauseIcon = styled(MdPause)`
  font-size: 36px;
  color: #222222;
`;

const PersonIcon = styled(MdOutlinePerson)`
  font-size: 33px;
  color: #575757;
`;
