import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdArrowBack, MdMenu } from "react-icons/md";

import "../styles/Login.css";
import { useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";
import Spinner from "../components/CTA/Spinner";

export default function Login({
  authToken,
  email,
  setEmail,
  password,
  setPassword,
  signIn,
  signUp,
  username,
  setUsername,
  creatorData,
  shoppingCard,
}) {
  const [page, setPage] = useState("register");
  const [formIsFilled, setFormIsFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  // userdata in state packen
  useEffect(() => {
    if (authToken) {
      if (!isEmpty(shoppingCard) && !isEmpty(creatorData)) {
        navigate("/creator/" + creatorData.id + "/collab");
      } else {
        navigate("/");
      }
    }
  }, [authToken, shoppingCard, creatorData]);

  useEffect(() => {
    if (
      (page == "register" && isEmpty(username)) ||
      isEmpty(email) ||
      isEmpty(password) ||
      !validateEmail(email) ||
      password.length < 6
    ) {
      setFormIsFilled(false);
    } else {
      setFormIsFilled(true);
    }
  }, [username, email, password, page]);

  const signUpAndDisableButton = () => {
    setIsLoading(true);
    signUp();
  };

  const signInAndDisableButton = () => {
    setIsLoading(true);
    signIn();
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className="login">
      <div className="creator_topnav">
        <div className="creator_topnav_left" onClick={() => navigate("/")}>
          <ArrowBack /> Zurück
        </div>
        <div className="creator_topnav_right"></div>
      </div>
      <div className="collabodetail_topnav_bottom">
        {page == "login" ? (
          <>
            <div className="collabodetail_topnav_bottom_tab_active">
              Einloggen
            </div>
            <div
              className="collabodetail_topnav_bottom_tab"
              onClick={() => setPage("register")}
            >
              Neu Registrieren
            </div>
          </>
        ) : (
          <>
            <div
              className="collabodetail_topnav_bottom_tab"
              onClick={() => setPage("login")}
            >
              Einloggen
            </div>
            <div className="collabodetail_topnav_bottom_tab_active">
              Neu Registrieren
            </div>
          </>
        )}
      </div>

      {page == "register" ? (
        <div className="login_registerform">
          <div className="login_registerform_title">Neu Registrieren</div>

          <input
            type="text"
            className="login_input"
            placeholder="Name"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <input
            type="email"
            className="login_input"
            placeholder="Email-Adresse"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            type="password"
            className="login_input"
            placeholder="Passwort Mind. 6 Zeichen"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {formIsFilled ? (
            <>
              {!isLoading ? (
                <div
                  className="login_ctabtn"
                  onClick={() => signUpAndDisableButton()}
                >
                  Registrieren{" "}
                </div>
              ) : (
                <div className="login_ctabtn_disabled" disabled={"disabled"}>
                  <Spinner />
                </div>
              )}
            </>
          ) : (
            <div className="login_ctabtn_disabled" disabled={"disabled"}>
              Registrieren
            </div>
          )}
          <div className="">
            {/* username == "" ? "Benutzername muss ausgefüllt werden" : "" */}
          </div>
          {/*
          <div className="login_divider">oder</div>
          <div className="login_sociallogin">Mit Google registrieren</div>
          <div className="login_sociallogin">Mit Facebook registrieren</div>
          */}
        </div>
      ) : (
        <div className="login_registerform">
          <div className="login_registerform_title">Einloggen</div>

          <input
            type="email"
            className="login_input"
            placeholder="Email-Adresse"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            type="password"
            className="login_input"
            placeholder="Passwort"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {formIsFilled ? (
            <>
              {!isLoading ? (
                <div
                  className="login_ctabtn"
                  onClick={() => signInAndDisableButton()}
                >
                  Einloggen{" "}
                </div>
              ) : (
                <div className="login_ctabtn_disabled" disabled={"disabled"}>
                  <Spinner />
                </div>
              )}
            </>
          ) : (
            <div className="login_ctabtn_disabled" disabled={"disabled"}>
              Einloggen{" "}
            </div>
          )}
          {/*
                    <div className="login_divider">oder</div>
          <div className="login_sociallogin">Mit Google einloggen</div>
          <div className="login_sociallogin">Mit Facebook einloggen</div>
        
          */}
        </div>
      )}
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBack)`
  font-size: 20px;
  color: #636363;
  margin-top: 2px;
`;
