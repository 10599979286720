import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdMenu } from "react-icons/md";

import "../styles/Home.css";
import TopNavbar from "../components/navbars/HomeTopNav";
import { ServicesBar } from "../components/cards/ServicesBar";
import { isEmpty } from "../functions/isEmpty";
import { destroySession } from "../functions/authentication";
import { useNavigate } from "react-router";

export default function Settings({
  isMobile,
  apiURL,
  serviceCategories,
  authToken,
  services,
  destroySession,
}) {
  const [page, setPage] = useState("fashion");
  let navigate = useNavigate();

  const logout = () => {
    destroySession();
    navigate("/");
  };

  return (
    <div className="home" onClick={() => logout()}>
      Ausloggen
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;
