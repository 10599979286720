import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdMenu, MdSettings } from "react-icons/md";

import "../styles/Profile.css";
import TopNavbar from "../components/navbars/HomeTopNav";
import { ServicesBar } from "../components/cards/ServicesBar";
import { isEmpty } from "../functions/isEmpty";
import { useNavigate } from "react-router";
import Bottomnavbar from "../components/navbars/Bottomnavbar";

export default function Profile({
  isMobile,
  apiURL,
  authToken,
  services,
  companyData,
  serviceCategories,
}) {
  const [page, setPage] = useState("fashion");
  let navigate = useNavigate();

  const [fashion, setFashion] = useState([]);
  const [beauty, setBeauty] = useState([]);
  const [software, setSoftware] = useState([]);
  const [business, setBusiness] = useState([]);
  const [gaming, setGaming] = useState([]);
  const [food, setFood] = useState([]);

  useEffect(() => {
    if (!isEmpty(serviceCategories)) {
      // Ignoriere die Groß-/Kleinschreibung beim Vergleichen der Kategorien
      const ignoreCase = (str1, str2) =>
        str1.localeCompare(str2, undefined, { sensitivity: "base" }) === 0;

      // Iteriere durch serviceCategories und kopiere die Elemente in die entsprechenden State-Arrays
      serviceCategories.forEach((item) => {
        const { contentCategory } = item;

        if (ignoreCase(contentCategory, "Fashion")) {
          setFashion((prevFashion) => [...prevFashion, item]);
        } else if (ignoreCase(contentCategory, "Beauty")) {
          setBeauty((prevBeauty) => [...prevBeauty, item]);
        } else if (ignoreCase(contentCategory, "Software")) {
          setSoftware((prevSoftware) => [...prevSoftware, item]);
        } else if (ignoreCase(contentCategory, "Business")) {
          setBusiness((prevBusiness) => [...prevBusiness, item]);
        } else if (ignoreCase(contentCategory, "Gaming")) {
          setGaming((prevGaming) => [...prevGaming, item]);
        } else if (ignoreCase(contentCategory, "Food")) {
          setFood((prevFood) => [...prevFood, item]);
        }
        // Ignoriere andere Kategorien
      });
    }
  }, [serviceCategories]);

  // für alle serviceCategorys ein objekt erzeugen
  // in die objekte services reinschicken und mappen ob id übereinstimmt

  return (
    <div className="profile">
      <div className="profile_topnav">
        <div className="profile_topnav_left">Profile</div>
        <div
          className="profile_topnav_right"
          onClick={() => navigate("/settings")}
        >
          <SettingsIcon />
        </div>
      </div>
      <div className="profile_details">
        <div className="profile_details_imgBox">
          <img
            src={
              !isEmpty(companyData)
                ? JSON.parse(companyData.companyPictures)[0].url
                : ""
            }
            className="profile_details_imgBox_img"
          />
        </div>
        <div className="profile_details_name">{companyData?.companyname}</div>
        <div className="profile_details_followernumber">
          {companyData?.followernumber}
        </div>
        <div className="profile_details_description">
          {companyData?.description}
        </div>
        <div className="profile_details_channels"></div>
      </div>
      <div className="profile_services">
        <div className="profile_services_box">
          <div className="profile_services_box_title">Fashion</div>
          <div className="profile_services_box_scrollRow">
            {fashion.map((service) => (
              <div
                className="profile_services_box_scrollRow_element"
                onClick={() => navigate("/create/" + service.id)}
              >
                {service.name}
              </div>
            ))}
          </div>
        </div>
        <div className="profile_services_box">
          <div className="profile_services_box_title">Gaming</div>
          <div className="profile_services_box_scrollRow">
            {gaming.map((service) => (
              <div
                className="profile_services_box_scrollRow_element"
                onClick={() => navigate("/create/" + service.id)}
              >
                {service.name}
              </div>
            ))}
          </div>
        </div>
        <div className="profile_services_box">
          <div className="profile_services_box_title">Software</div>
          <div className="profile_services_box_scrollRow">
            {software.map((service) => (
              <div
                className="profile_services_box_scrollRow_element"
                onClick={() => navigate("/create/" + service.id)}
              >
                {service.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Bottomnavbar isMobile={isMobile} />
    </div>
  );
}

const SettingsIcon = styled(MdSettings)`
  font-size: 25px;
  color: #575757;
`;
