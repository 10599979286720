import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBack,
  MdArrowBackIos,
  MdCampaign,
  MdChildCare,
  MdFavoriteBorder,
  MdLanguage,
  MdLightMode,
  MdMenu,
  MdOutlineCampaign,
  MdPersonOutline,
  MdRestaurant,
  MdSearch,
} from "react-icons/md";
import "../../styles/Topnavbar.css";
import { useLocation, useNavigate } from "react-router";
import {
  FaBlog,
  FaDollarSign,
  FaGamepad,
  FaLanguage,
  FaTshirt,
} from "react-icons/fa";
import { FaPerson, FaSnapchat } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";

import { useLanguage } from "../../contexts/LanguageContext";
import texts from "../../texts/Texts";
import Bottomnavbar from "./Bottomnavbar";
import { isEmpty } from "../../functions/isEmpty";

export default function DefaultTopNav({
  isMobile,
  page,
  setPage,
  authToken,
  cart,
}) {
  let navigate = useNavigate();
  const { language, switchLanguage } = useLanguage();
  const location = useLocation();

  const handleLanguageChange = (newLanguage) => {
    switchLanguage(newLanguage);
  };

  useEffect(() => {}, [location]);

  return (
    <>
      {isMobile ? (
        <div className="defaulttopnav">
          <div
            className="defaulttopnav_backHitBox"
            onClick={() => navigate("/creator/1")}>
            <div className="defaulttopnav_backHitBox_backButton">
              <ArrowBack />
            </div>
            Zurück
          </div>
        </div>
      ) : (
        <div className="defaulttopnav">
          <div className="topnavbar_top">
            <div className="topnavbar_top_left">
              <div
                className="topnavbar_top_left_logo"
                onClick={() => navigate("/")}>
                {/*
                <img
                src="../../../logo-desktop.png"
                className="topnavbar_top_left_logo"
              />
              */}
                creator-ads
              </div>
            </div>
            <div className="topnavbar_top_navbar">
              {/*
                  {language == "en" ? (
                <div
                  className="topnavbar_top_left_language"
                  onClick={() => handleLanguageChange("de")}
                >
                  EN
                </div>
              ) : (
                <div
                  className="topnavbar_top_left_language"
                  onClick={() => handleLanguageChange("en")}
                >
                  DE
                </div>
              )}
                  */}
              <div
                className="topnavbar_top_navbar_navElement"
                onClick={() => navigate("/cart")}>
                {cart.length + " "} {texts[language].orderIcon}
              </div>
              <div
                className="topnavbar_top_navbar_navElement"
                onClick={() => navigate("/profile")}>
                {texts[language].profileIcon}
              </div>
            </div>
          </div>
        </div>
      )}

      {!isEmpty(authToken) && <Bottomnavbar isMobile={isMobile} />}
    </>
  );
}

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const ArrowDropdown = styled(MdArrowBackIos)`
  font-size: 12px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-left: 2px;
`;

const MenuIcon = styled(MdMenu)`
  font-size: 29px;
  color: #ffffff;
`;

/* */

const SearchIcon = styled(MdSearch)`
  font-size: 26px;
  color: #8f8f8f;
`;

const FavoriteIcon = styled(MdFavoriteBorder)`
  font-size: 26px;
  color: #8f8f8f;
`;

const OrderIcon = styled(MdOutlineCampaign)`
  font-size: 26px;
  color: #8f8f8f;
`;

const PersonIcon = styled(MdPersonOutline)`
  font-size: 26px;
  color: #8f8f8f;
`;

const FashionIcon = styled(FaTshirt)`
  font-size: 26px;
  color: #8f8f8f;
`;

const LanguageIcon = styled(MdLanguage)`
  font-size: 26px;
  color: #8f8f8f;
`;

const BeautyIcon = styled(BsStars)`
  font-size: 26px;
  color: #8f8f8f;
`;

const BusinessIcon = styled(FaDollarSign)`
  font-size: 26px;
  color: #8f8f8f;
`;

const GamingIcon = styled(FaGamepad)`
  font-size: 26px;
  color: #8f8f8f;
`;

const FoodIcon = styled(MdRestaurant)`
  font-size: 26px;
  color: #8f8f8f;
`;

const FamilyIcon = styled(MdChildCare)`
  font-size: 26px;
  color: #8f8f8f;
`;

/* ACTIVE */

const FashionIconActive = styled(FaTshirt)`
  font-size: 26px;
  color: #f58700;
`;

const BeautyIconActive = styled(BsStars)`
  font-size: 26px;
  color: #f58700;
`;

const BusinessIconActive = styled(FaDollarSign)`
  font-size: 26px;
  color: #f58700;
`;

const GamingIconActive = styled(FaGamepad)`
  font-size: 26px;
  color: #f58700;
`;

const FoodIconActive = styled(MdRestaurant)`
  font-size: 26px;
  color: #f58700;
`;

const FamilyIconActive = styled(MdChildCare)`
  font-size: 26px;
  color: #f58700;
`;
